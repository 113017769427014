import React, { useState } from 'react'

import './avatarstats.css'

const AvatarStats = () => {

  return (
    <div>
      <h1>This is the subpage</h1>
    </div>
  );
}

export default AvatarStats